@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-padding-top: 88px;
}

body {
	display: block;
}

/* font-sans regular */
@font-face {
	font-family: "Sanofi Sans";
	src: url(../assets/fonts/Sanofi_Sans-Regular.woff2) format("woff2");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

/* font-sans bold */
@font-face {
	font-family: "Sanofi Sans";
	src: url(../assets/fonts/Sanofi_Sans-Bold.woff2) format("woff2");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

.slick-track {
	display: flex !important;
	align-items: stretch;
}

.slick-slide {
	height: auto !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

/* this needs to be outside the components layer as it is not compiled until the browser loads */
.slick-dots {
	@apply absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex flex-row justify-center items-center gap-2;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
	@apply block w-2.5 h-2.5 cursor-pointer relative;
}

.slick-dots li button::after {
	content: "";
	@apply block absolute w-full h-full rounded-max bg-transparent border border-neutral-500 top-0 left-0;
}

.slick-dots li.slick-active button::after {
	content: "";
	@apply block absolute w-full h-full rounded-max bg-primary-darkest border border-primary-darkest;
}

.slick-arrow {
	font-size: 0;
	line-height: 0;
	@apply block w-8 h-8 cursor-pointer relative;
}

.slick-arrow::after {
	content: url("/assets/img/arrow-dot.svg");
	@apply block absolute w-full h-full top-0 left-0;
}

.slick-arrow.slick-prev::after {
	@apply rotate-180;
}

#mobile-navigation {
	max-height: calc(90vh - 140px);
	height: calc(90vh - 140px);
}

#external-link-modal {
	backdrop-filter: blur(4px);
}

@keyframes bounce-in-top {
	0% {
		transform: translateY(-500px);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	38% {
		transform: translateY(0);
		animation-timing-function: ease-out;
		opacity: 1;
	}
	55% {
		transform: translateY(-65px);
		animation-timing-function: ease-in;
	}
	72% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}
	81% {
		transform: translateY(-28px);
		animation-timing-function: ease-in;
	}
	90% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}
	95% {
		transform: translateY(-8px);
		animation-timing-function: ease-in;
	}
	100% {
		transform: translateY(0);
		animation-timing-function: ease-out;
		opacity: 1;
	}
}

#ot-sdk-btn-floating {
	bottom: 20px !important;
	right: 20px !important;
	left: auto !important;
}

@layer base {
	body {
		@apply text-base leading-loose font-normal;
	}
	h1,
	.h1 {
		@apply text-900 md:text-1000 lg:text-1100;
	}
	h2,
	.h2 {
		@apply text-700 md:text-800 lg:text-900;
	}
	h3,
	.h3 {
		@apply text-500 md:text-600;
	}
	h4,
	.h4 {
		@apply text-400;
	}
	h5,
	.h5 {
		@apply text-base;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5 {
		@apply font-bold leading-regular mt-8 mb-6 first:mt-0 last:mb-0;
	}

	a {
		@apply transition-all;
	}

	p {
		@apply my-6 first:mt-0 last:mb-0;
	}

	svg,
	svg * {
		@apply transition-all;
	}

	tr:not(:first-child) {
		@apply even:bg-neutral-50 hover:bg-neutral-100;
	}

	tr:first-child {
		@apply border-b border-neutral-400;
	}
	td, th {
		@apply p-6 text-left align-top min-w-min;
	}

	table {
		@apply w-full;
	}
}

@layer utilities {
}

@layer components {
	.legal-copy {
		@apply text-200 leading-regular;
	}

	.link {
		@apply underline text-primary hover:text-primary-dark;
	}

	.link-white {
		@apply text-white hover:opacity-80 underline;
	}

	.button {
		@apply px-6 py-3.5 w-fit flex justify-center items-center bg-primary hover:bg-primary-dark text-white rounded leading-regular;
	}

	.button-outline {
		@apply px-6 py-3.5 w-fit flex justify-center items-center border border-primary hover:border-primary-dark text-primary hover:text-primary-dark rounded leading-regular;
	}

	.button-outline-white {
		@apply px-6 py-3.5 w-fit flex justify-center items-center border border-white hover:border-white/80 text-white hover:text-white/80 rounded leading-regular;
	}

	.button-arrow {
		@apply flex justify-start items-center leading-regular gap-4 text-black hover:underline font-bold;
	}

	.button-arrow:after {
		@apply block w-4 h-4 bg-primary mb-1 flex-shrink-0;
		content: "";
		mask-repeat: no-repeat;
		mask-size: contain;
		mask-image: url('data:image/svg+xml,<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path></svg>');
	}

	.button-arrow:hover:after {
		@apply bg-primary-dark;
	}

	.large-container {
		@apply px-4 md:px-8;
	}

	.bg-image {
		@apply bg-cover bg-center bg-no-repeat;
	}

	.mobile-has-children.open > a .icon {
		@apply rotate-0;
	}

	#hamburger-icon span {
		@apply relative block w-8 h-1 rounded-max mb-1.5 bg-current transition-all last:mb-0 origin-center;
	}

	#primary-navigation--button.open #hamburger-icon span:nth-child(1) {
		@apply rotate-45 w-[29px] translate-y-2.5;
	}

	#primary-navigation--button.open #hamburger-icon span:nth-child(2) {
		@apply opacity-0;
	}

	#primary-navigation--button.open #hamburger-icon span:nth-child(3) {
		@apply -rotate-45 w-[29px] -translate-y-2.5;
	}

	.bounce-in-top {
		animation: bounce-in-top 1.1s;
		animation-fill-mode: forwards;
	}

	.grid-2 {
		@apply grid grid-cols-1 md:grid-cols-2 gap-4;
	}

	.grid-3 {
		@apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4;
	}

	.grid-4 {
		@apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4;
	}

	.grid-5 {
		@apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4;
	}

	.flex-row-left {
		@apply flex flex-col md:flex-row gap-x-12 gap-y-8;
	}

	.flex-row-right {
		@apply flex flex-col md:flex-row-reverse gap-x-12 gap-y-8;
	}

	.list-disc li {
		@apply my-2 first:mt-0 last:mb-0;
	}

	/* width */
	.custom-scroll::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	.custom-scroll::-webkit-scrollbar-track {
		@apply bg-neutral-50;
	}

	/* Handle */
	.custom-scroll::-webkit-scrollbar-thumb {
		@apply bg-neutral-500;
	}

	/* Handle on hover */
	.custom-scroll::-webkit-scrollbar-thumb:hover {
		@apply bg-neutral-700;
	}
}

.video-embed-container,
.video-embed-container2 {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-embed-container iframe,
.video-embed-container2 iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-embed-container:after {
	opacity: 0;
	transition: all 1s ease-in-out 1s;
}

.video-embed-container.ended:after {
	opacity: 1;
	content:"";
	position:absolute;
	top:0;
	left:0;
	bottom:0;
	right:0;
	cursor:pointer;
	background-color:black;
	background-repeat:no-repeat;
	background-position:center;
	background-size:64px 64px;
	background-image:url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
}

.video-embed-container.paused::after {
	opacity: 1;
	content:"";
	position:absolute;
	top:0;
	left:0;
	bottom:0;
	right:0;
	cursor:pointer;
	background-color:black;
	background-repeat:no-repeat;
	background-position:center;
	background-size:40px 40px;
	background-image:url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHdpZHRoPSIxNzA2LjY2NyIgaGVpZ2h0PSIxNzA2LjY2NyIgdmlld0JveD0iMCAwIDEyODAgMTI4MCI+PHBhdGggZD0iTTE1Ny42MzUgMi45ODRMMTI2MC45NzkgNjQwIDE1Ny42MzUgMTI3Ny4wMTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
}